var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-actions',{ref:"formCard",attrs:{"title":((_vm.editMode ? 'Ubah' : 'Tambah') + " " + (_vm.$route.meta.name.singular)),"no-actions":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-observer',{ref:"formRules"},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"md":"12"}},[_c('h3',{staticClass:"mb-2"},[_vm._v(" Profil ")]),_c('b-form-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"text-center mr-md-1"},[_c('b-avatar',{attrs:{"size":"86px","src":_vm.imageFile ? _vm.fileUrl(_vm.imageFile) : _vm.imageInitialFile}})],1)]),_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":"Foto profil","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Foto profil","rules":!_vm.editMode ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.imageInitialFile
                            ? _vm.imageInitialFile.split('/').pop()
                            : "Pilih atau drop file disini...","drop-placeholder":"Drop file disini...","accept":"image/*"},model:{value:(_vm.imageFile),callback:function ($$v) {_vm.imageFile=$$v},expression:"imageFile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama lengkap","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Nama","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.submitErrors.name ? false : null,"formatter":_vm.capitalize},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.name))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Username","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length || _vm.submitErrors.username > 0
                        ? false
                        : null},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.username))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Jenis kelamin","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Jenis kelamin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"mt-50",attrs:{"options":_vm.genderOptions},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Tanggal lahir","label-cols-md":"4"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                    altInput: true,
                    altFormat: 'j F Y',
                    dateFormat: 'Y-m-d',
                  }},model:{value:(_vm.birthDate),callback:function ($$v) {_vm.birthDate=$$v},expression:"birthDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.submitErrors.birth_date))])],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Status pernikahan","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Status pernikahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"mt-50",attrs:{"options":_vm.maritalOptions},model:{value:(_vm.marital),callback:function ($$v) {_vm.marital=$$v},expression:"marital"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('h3',{staticClass:"my-2"},[_vm._v(" Alamat ")]),_c('b-form-group',{attrs:{"label":"Alamat","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Alamat","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 || _vm.submitErrors.address ? false : null},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.address))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Provinsi","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Provinsi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":_vm.provinceItems,"state":errors.length > 0 || _vm.submitErrors.province_id
                        ? false
                        : null,"placeholder":"Ketik untuk mencari..."},model:{value:(_vm.provinceId),callback:function ($$v) {_vm.provinceId=$$v},expression:"provinceId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.province_id))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Kota","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Kota","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":_vm.regencyItems,"disabled":!_vm.provinceId,"state":errors.length > 0 || _vm.submitErrors.regency_id
                        ? false
                        : null,"placeholder":"Ketik untuk mencari..."},model:{value:(_vm.regencyId),callback:function ($$v) {_vm.regencyId=$$v},expression:"regencyId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.regency_id))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Kecamatan","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Kecamatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":_vm.districtItems,"disabled":!_vm.regencyId,"state":errors.length > 0 || _vm.submitErrors.district_id
                        ? false
                        : null,"placeholder":"Ketik untuk mencari..."},model:{value:(_vm.districtId),callback:function ($$v) {_vm.districtId=$$v},expression:"districtId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.district_id))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('h3',{staticClass:"my-2"},[_vm._v(" Data Komerce ")]),_c('b-form-group',{attrs:{"label":"Divisi","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Divisi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"division_name","reduce":function (option) { return option.id; },"options":_vm.divisionItems,"state":errors.length > 0 ? false : null,"placeholder":"Ketik untuk mencari..."},model:{value:(_vm.divisionId),callback:function ($$v) {_vm.divisionId=$$v},expression:"divisionId"}},[(_vm.hasMoreDivision)?_c('li',{staticClass:"\n                        vs__dropdown-option vs__dropdown-option--disabled\n                      ",attrs:{"slot":"list-footer"},slot:"list-footer"},[_c('feather-icon',{attrs:{"icon":"MoreHorizontalIcon","size":"16"}})],1):_vm._e()]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Posisi","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Posisi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"position_name","reduce":function (option) { return option.id; },"options":_vm.positionItems,"state":errors.length > 0 ? false : null,"filterable":false,"disabled":!_vm.divisionId,"placeholder":"Ketik untuk mencari..."},on:{"search":_vm.onSearchPosition},model:{value:(_vm.positionId),callback:function ($$v) {_vm.positionId=$$v},expression:"positionId"}},[(_vm.hasMorePosition)?_c('li',{staticClass:"\n                        vs__dropdown-option vs__dropdown-option--disabled\n                      ",attrs:{"slot":"list-footer"},slot:"list-footer"},[_c('feather-icon',{attrs:{"icon":"MoreHorizontalIcon","size":"16"}})],1):_vm._e()]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Tanggal Bergabung","label-cols-md":"4"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                    altInput: true,
                    altFormat: 'j F Y',
                    dateFormat: 'Y-m-d',
                  }},model:{value:(_vm.joinDate),callback:function ($$v) {_vm.joinDate=$$v},expression:"joinDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.submitErrors.join_date))])],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Working space","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Working space","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"office_name","reduce":function (option) { return option.id; },"options":_vm.workingSpaceItems,"state":errors.length > 0 ? false : null,"placeholder":"Ketik untuk mencari..."},model:{value:(_vm.workingSpaceId),callback:function ($$v) {_vm.workingSpaceId=$$v},expression:"workingSpaceId"}},[(_vm.hasMoreWorkingSpace)?_c('li',{staticClass:"\n                        vs__dropdown-option vs__dropdown-option--disabled\n                      ",attrs:{"slot":"list-footer"},slot:"list-footer"},[_c('feather-icon',{attrs:{"icon":"MoreHorizontalIcon","size":"16"}})],1):_vm._e()]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('h3',{staticClass:"my-2"},[_vm._v(" Kontak ")]),_c('b-form-group',{attrs:{"label":"No hp","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"No hp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"options":_vm.options.phone},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"email","state":errors.length > 0 || _vm.submitErrors.email ? false : null},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.email))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('h3',{staticClass:"my-2"},[_vm._v(" Akun Bank ")]),_c('b-form-group',{attrs:{"label":"Nama bank","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Nama Bank","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.name; },"options":_vm.banks,"filterable":true,"state":errors.length > 0 ? false : null,"placeholder":"Ketik untuk mencari..."},model:{value:(_vm.bankName),callback:function ($$v) {_vm.bankName=$$v},expression:"bankName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"No rekening","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"No rekening","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.accountNumber),callback:function ($$v) {_vm.accountNumber=$$v},expression:"accountNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama pemilik bank","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Nama pemilik bank","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"formatter":_vm.capitalize,"state":errors.length > 0 ? false : null},model:{value:(_vm.accountName),callback:function ($$v) {_vm.accountName=$$v},expression:"accountName"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(!_vm.editMode)?_c('b-col',{attrs:{"md":"12"}},[_c('h3',{staticClass:"my-2"},[_vm._v(" Password ")]),_c('b-form-group',{attrs:{"label":"Password","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","state":errors.length > 0 ? false : null},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2299747772)})],1)],1):_vm._e(),(!_vm.editMode)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Konfirmasi password","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Konfirmasi password","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"password"},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2101547662)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('hr',{staticClass:"mb-2"}),_c('b-button',{staticClass:"mr-50",attrs:{"variant":_vm.editMode ? 'warning' : 'primary',"type":"submit","disabled":_vm.loadingSubmit},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[(_vm.loadingSubmit)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Submit ")],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }